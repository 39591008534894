import React from "react";
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import "./Marketing.scss";
import MarketingContent from "./MarketingContent";
import LinkedLogo from "../LinkedLogo";
import Registration from "../Registration";
import LoginReset from "../LoginReset";
import Activation from "../Activation";
import Contact from "../Contact";
import TermsOfService from "../TermsOfService";
import Login from "../Login";
import Imprint from "../Imprint";
import Privacy from "../Privacy";
import About from "../About";
import Parallax from "../Parallax";

import parallaxImageImpericon from "../../assets/background-impericon.jpg";

const Marketing = ({ ...props }) => {
  return (
    <BrowserRouter>
      <div className="marketing">
        <nav>
          {!!parseInt(process.env.REACT_APP_REGISTRATION_ENABLED) && (
            <Registration />
          )}
          <Login />
        </nav>
        <main>
          <Switch>
            <Route exact path="/">
              <MarketingContent />
            </Route>
            <Route path="/about">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>About</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <About />
              </section>
            </Route>
            <Route path="/imprint">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Imprint</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <Imprint />
              </section>
            </Route>
            <Route path="/termsofservice">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Terms of Service</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <TermsOfService />
              </section>
            </Route>
            <Route path="/privacy">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Privacy Policy</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <Privacy />
              </section>
            </Route>
            <Route path="/activate/:jwt">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Account Activation</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <Activation />
              </section>
            </Route>
            <Route path="/loginhelp">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Forgot Password</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <LoginReset />
              </section>
            </Route>
            <Route path="/contact">
              <Parallax backgroundImage={parallaxImageImpericon} height="20rem">
                <h1>Contact</h1>
                <LinkedLogo style={{ height: "15rem" }} />
              </Parallax>
              <section className="description">
                <Contact />
              </section>
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </main>
        <footer>
          <RouterLink to="/imprint">Imprint</RouterLink>
          <RouterLink to="/contact">Contact Us</RouterLink>
          <RouterLink to="/termsofservice">Terms of Service</RouterLink>
          <RouterLink to="/privacy">Privacy</RouterLink>
          <RouterLink to="/about">About</RouterLink>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default Marketing;
