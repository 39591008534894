import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import allActions from "../../actions";

import {
  checkPasswordStrength,
  passwordStrengthMessage,
} from "../../utils/checkPassword";
import { verifyEmail } from "../../utils/verifyEmail";

import ErrorAlert from "../ErrorAlert";
import Metalhand from "../Metalhand";

import "./Registration.scss";

export default function Registration({
  buttonSize = "medium",
  buttonVariant = "text",
  ...props
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [errorMessages, setErrorMessages] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateInput = () => {
    let errors = [];
    setPasswordError(false);
    setUsernameError(false);
    setEmailError(false);
    setErrorMessages(false);

    if (password && passwordStrength < 4) {
      errors.push(
        "Your password is too weak, please enter a long, strong password, ideally containing: upper- and lower case letters, numbers and special characters."
      );
      setPasswordError(true);
    }

    if (!password) {
      errors.push("Please enter a password!");
      setPasswordError(true);
    }

    if (!username) {
      errors.push("Please enter a name!");
      setUsernameError(true);
    }

    if (!email) {
      errors.push("Please enter an e-mail address!");
      setEmailError(true);
    }

    if (email && !verifyEmail(email)) {
      errors.push("Please enter a valid e-mail address!");
      setEmailError(true);
    }

    if (!privacy) {
      errors.push("Please read and accept the Privacy Policy!");
      setPrivacyError(true);
    }

    if (errors.length) {
      setErrorMessages(errors);
      return false;
    }

    return true;
  };

  const handleRegister = async () => {
    if (!validateInput()) {
      return;
    }

    dispatch(
      allActions.user.registerUser({
        username,
        email,
        password,
        successCallback: () => {
          setSuccess(true);
        },
        failCallback: (error) => {
          console.error(error.response);
          setErrorMessages(error.response);
        },
      })
    );
  };

  if (user.jwt) {
    return <></>;
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={handleClickOpen}
        variant={buttonVariant}
        size={buttonSize}
      >
        Register
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="register-dialog"
      >
        {success && (
          <>
            <DialogTitle id="responsive-dialog-title">
              Registration successful!
            </DialogTitle>
            <DialogContent>
              <p>
                Hey {user.username}!<br />
                <br />
                Thank you for registering with MetalStreams.tv! <br />
                Please check your e-mails and activate your account.
                <br />
                <br />
                Rock on, see you then! <Metalhand />
              </p>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        )}

        {!success && (
          <>
            <DialogTitle id="responsive-dialog-title">Register</DialogTitle>
            <DialogContent>
              <ErrorAlert errorAction="registration" errors={errorMessages} />
              <p>
                Registration just takes a minute, please fill out this form and
                you're ready to rock! <Metalhand />
              </p>
              <TextField
                required
                autoFocus
                label="Username"
                color="secondary"
                className="register-input"
                value={username}
                onChange={(event) => {
                  setUsernameError(false);
                  setUsername(event.target.value.trim());
                }}
                error={usernameError}
              />
              <TextField
                required
                label="E-Mail"
                color="secondary"
                className="register-input"
                value={email}
                onChange={(event) => {
                  setEmailError(false);
                  setEmail(event.target.value.trim());
                }}
                error={emailError}
              />
              <TextField
                className="register-input"
                label="Password"
                type="password"
                color="secondary"
                value={password}
                onChange={(event) => {
                  setPasswordError(false);
                  setPasswordStrength(
                    checkPasswordStrength(event.target.value)
                  );
                  setPassword(event.target.value.trim());
                }}
                error={passwordError}
                required
                autoComplete="password"
                helperText={passwordStrengthMessage(passwordStrength)}
              />
              <FormControl required error={privacyError}>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      checked={privacy}
                      onChange={(event) => {
                        setPrivacyError(false);
                        setPrivacy(event.target.checked);
                      }}
                      name="privacypolicy_accepted"
                    />
                  }
                  label={
                    <FormLabel component="legend">
                      I have read and accepted the{" "}
                      <Link
                        component={RouterLink}
                        to="/privacy"
                        color="secondary"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </FormLabel>
                  }
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="secondary">
                Back
              </Button>
              <Button onClick={handleRegister} color="secondary">
                Register
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
