import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import Registration from "../Registration";
import Parallax from "../Parallax";
import LinkedLogo from "../LinkedLogo";
import FAQ from "../FAQ";
import List from "../List";
import Cardicle from "../Cardicle";
import parallaxImageImpericon from "../../assets/background-impericon.jpg";
import parallaxImageRockharz from "../../assets/background-rockharz.jpg";
import parallaxImageWerk2 from "../../assets/background-werk2.jpg";

const MarketingContent = ({ ...props }) => {
  const events = useSelector((state) => state.events.upcoming);

  return (
    <>
      <Parallax backgroundImage={parallaxImageImpericon}>
        <h1 className="caption-h1">
          Welcome to <LinkedLogo style={{ height: "20rem" }} />
        </h1>
        <h2 className="caption-h2">
          We're a small project of Metalheads striving to bring your favourite
          bands into your living room.
        </h2>
        <Button
          component={RouterLink}
          to="/about"
          color="secondary"
          variant="contained"
        >
          Find out more about our project
        </Button>
      </Parallax>
      <section className="description">
        Our goal is to give bands and labels a way to publish their live
        concerts in a secure and easy way, eliminating as many unknown variables
        as possible. Predictable cost and a stable delivery are our main goals
        while providing fans with an experience they will enjoy.
        <br />
        <br />
        Additionally, we're working on providing a comprehensive list of
        upcoming metal streaming events which you'll be able to add to a
        calendar you can easily integrate into your computer, smartphone and
        gadgets without additional software.
        <br />
        <h3>Upcoming Events:</h3>
        <List dataSource={events} withGrid={true}>
          <Cardicle />
        </List>
      </section>
      <Parallax backgroundImage={parallaxImageRockharz}>
        <h2 className="caption-h2">Support your local scene!</h2>
        <h3 className="caption-h3">
          The bands decide how much a stream ticket will cost and you decide if
          you want to give more.
        </h3>
      </Parallax>
      <section className="description">
        <FAQ />
      </section>
      {!!parseInt(process.env.REACT_APP_REGISTRATION_ENABLED) && (
        <Parallax backgroundImage={parallaxImageWerk2}>
          <h2 className="caption-h2">
            Register today and be one of the first to enjoy MetalStreams.tv!
          </h2>
          <Registration buttonVariant="contained" buttonSize="large" />
        </Parallax>
      )}
    </>
  );
};

export default MarketingContent;
