import React from "react";
import { passwordStrength as checkPasswordStrength } from "password-strength-score";

import Metalhand from "../components/Metalhand";

const passwordStrengthMessage = (strength = 0) => {
  switch (strength) {
    case 1:
    case 2:
    case 3:
      return <span>This password is too weak, please make it stronger.</span>;
    case 4:
      return <span>This password is strong enough.</span>;
    case 5:
      return <span>This password is strong.</span>;
    case 6:
      return (
        <span>
          This password is brutally strong and epic! <Metalhand />
        </span>
      );
    case 0:
    default:
      return (
        <span>
          Please enter a long, strong password, ideally containing: upper- and
          lower case letters, numbers and special characters.
        </span>
      );
  }
};

export { passwordStrengthMessage, checkPasswordStrength };
