import { isArray, isEmpty, isObject } from "lodash-es";

export function parseDataSource(dataSource = {}) {
  let image = { url: "" };
  if (isArray(dataSource.image) && dataSource.image[0]) {
    image = dataSource.image[0];
  } else if (isObject(dataSource.image) && dataSource.image.url) {
    image.url = dataSource.image.url;
  }
  let article = {
    id: dataSource.id || -1,
    date: dataSource.date || 0,
    bandName: dataSource.name || "",
    image: image,
    description: dataSource.description || "",
    eventInfolink: dataSource.eventInfolink || "",
    mediastream: dataSource.mediastream || {
      livestreamExternal: null,
      videoOnDemand: null,
      livestream: null,
      livestreamLowLatency: null,
      videoOnDemandExternal: null,
      livestreamPoster: null,
    },
    chat: dataSource.chat || false,
    price: dataSource.price || 0,
    priceInformation: dataSource.priceInformation || false,
    ticketStore: dataSource.store || false,
  };

  if (isEmpty(article.mediastream.livestreamPoster)) {
    article.mediastream.livestreamPoster = { url: "" };
  }

  const date = new Date(article.date).toLocaleDateString();
  const time = new Date(article.date)
    .toLocaleTimeString()
    .split(":")
    .slice(0, 2)
    .join(":");

  return [article, date, time];
}
