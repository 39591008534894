import { SET_EVENTS_BOUGHT } from "../actions/types";

const boughtEventStructure = {
  events: false,
  transactions: false,
  eventIDs: [],
};

const ownedEvents = (state = boughtEventStructure, action) => {
  switch (action.type) {
    case SET_EVENTS_BOUGHT:
      let newValue = { ...state, ...action.payload };
      newValue.eventIDs = newValue.events.map((event) => {
        return event.id;
      });
      return newValue;
    default:
      return state;
  }
};

export default ownedEvents;
