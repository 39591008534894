import React from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/logo.svg";

import "./LinkedLogo.scss";

const LinkedLogo = ({ style, ...props }) => {
  return (
    <>
      <RouterLink to="/" className="linked-logo" style={{ ...style }}>
        <img src={logo} alt="MetalStreams.tv" className="lazyload" />
      </RouterLink>
    </>
  );
};

export default LinkedLogo;
