import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Button } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import allActions from "../../actions";
import ErrorAlert from "../ErrorAlert";

import {
  checkPasswordStrength,
  passwordStrengthMessage,
} from "../../utils/checkPassword";

export default function ResetPassword({ ...props }) {
  const dispatch = useDispatch();
  const { resetCode } = useParams();
  const [success, setSuccess] = useState(false);
  const [errorMessages, setErrorMessages] = useState(false);
  const [password, setPassword] = useState();
  const [passwordStrength, setPasswordStrength] = useState();
  const [passwordError, setPasswordError] = useState();
  const history = useHistory();
  const validatePassword = () => {
    let passwordInvalid = false;
    let validationErrors = [];
    setErrorMessages(false);

    if (!password) {
      validationErrors.push("Please enter a password!");
      passwordInvalid = true;
    }

    if (password && passwordStrength < 4) {
      validationErrors.push(
        "Your password is too weak, please enter a long, strong password, ideally containing: upper- and lower case letters, numbers and special characters."
      );
      passwordInvalid = true;
    }

    if (passwordInvalid) {
      setErrorMessages(validationErrors);
      setPasswordError(passwordInvalid);
    }

    return !passwordInvalid;
  };

  const handleReset = async () => {
    if (!validatePassword()) {
      return;
    }
    setErrorMessages(false);

    dispatch(
      allActions.user.resetPassword({
        code: resetCode,
        password: password,
        passwordConfirmation: password,
        successCallback: () => {
          setSuccess(true);
        },
        failCallback: (error) => {
          setErrorMessages(error.response);
        },
      })
    );
  };

  if (!resetCode) {
    history.push("/helplogin");
  }

  if (success) {
    setTimeout(() => {
      history.push("/");
    }, 5000);

    return (
      <>
        <p>
          You have successfully changed your password, already been logged with
          your new credentials and will be redirected shortly.
        </p>
      </>
    );
  }

  return (
    <>
      <p>
        You successfully verified your request to reset your password. <br />
        Please enter your new password down below!
      </p>
      <section className="loginreset-form">
        <ErrorAlert errorAction="login reset" errors={errorMessages} />
        <TextField
          className="loginreset-input"
          label="Password"
          type="password"
          color="secondary"
          onChange={(event) => {
            setPasswordError(false);
            setPasswordStrength(checkPasswordStrength(event.target.value));
            setPassword(event.target.value);
          }}
          error={passwordError}
          required
          autoComplete="password"
          helperText={passwordStrengthMessage(passwordStrength)}
        />
        <section className="loginreset-actions">
          <Button onClick={handleReset} color="secondary">
            Save Password
          </Button>
        </section>
      </section>
    </>
  );
}
