import React from "react";
import { Provider } from "react-redux";

import { ThemeProvider, createMuiTheme } from "@material-ui/core";

import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

import store from "./store";
import Root from "./components/Root";

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Root />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
