export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENTS_PAST = "SET_EVENTS_PAST";
export const SET_EVENTS_UPCOMING = "SET_EVENTS_UPCOMING";
export const SET_EVENTS_FLAT = "SET_EVENTS_FLAT";

export const SET_EVENTS_OWNED = "SET_EVENTS_OWNED";

export const SET_EVENTS_BOUGHT = "SET_EVENTS_BOUGHT";

export const FETCH_EVENTS = "FETCH_EVENTS";

export const FETCH_USER = "FETCH_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_ERROR = "UPDATE_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const FORGOT_PASSWORD_MAIL = "FORGOT_PASSWORD_MAIL";
export const FORGOT_PASSWORD_MAIL_ERROR = "FORGOT_PASSWORD_MAIL_ERROR";
export const FORGOT_PASSWORD_MAIL_SUCCESS = "FORGOT_PASSWORD_MAIL_SUCCESS";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATION_ERROR = "ACTIVATION_ERROR";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";

export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

export const CHECKOUT = "CHECKOUT";
export const SET_CHECKOUT = "SET_CHECKOUT";
export const CHECKOUT_ERROR = "CHECKOUT_ERROR";

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const API_RESET_ERROR = "API_RESET_ERROR";
