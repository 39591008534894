import { Children, cloneElement } from "react";
import { isObject } from "lodash-es";

export function modifyChildren(children, modifiers) {
  let elements = Children.toArray(children);
  const elementModifier = isObject(modifiers) ? modifiers : {};

  if (elements.length === 1) {
    elements = cloneElement(elements[0], elementModifier);
  } else if (elements.length > 0) {
    let lastElement = elements[elements.length - 1];
    elements = [cloneElement(elements[0], elementModifier)]
      .concat(elements.slice(1, -1))
      .concat(cloneElement(lastElement, elementModifier));
  }
  return elements;
}
