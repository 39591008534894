import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import "./PaypalButton.scss";

const PaypalButton = ({
  amount = 0.01,
  handleValidation = () => true,
  finishTransaction = () => {},
  onCancel = () => {},
  onError = () => {},
  onButtonReady = false,
  ...props
}) => {
  const [paypalReady, setPaypalReady] = useState(false);

  const generateRandomTransactionID = () => {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 12;
    var randomstring = "";
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    return `MSFREE${randomstring}`;
  };

  const initializePaypal = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?currency=EUR&client-id=${process.env.REACT_APP_PAYPAL_CLIENT}`;
    script.async = true;
    script.onload = () => {
      setPaypalReady(true);
    };
    script.onerror = () => {
      console.error("Paypal SDK could not be loaded.");
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (window !== undefined && window.paypal === undefined) {
      initializePaypal();
    } else if (window !== undefined && window.paypal !== undefined) {
      setPaypalReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!paypalReady || window.paypal === undefined) {
    return <>Paypal is loading</>;
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "EUR",
            value: amount,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    try {
      if (finishTransaction) {
        return finishTransaction(data);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        console.error(error);
      }
    }
  };

  const PaypalButton = window.paypal.Buttons.driver("react", {
    React,
    ReactDOM,
  });

  if (Number.parseFloat(amount) <= 0) {
    return (
      <>
        <Button
          label="Add to Account"
          color="secondary"
          variant="contained"
          onClick={() => {
            if (handleValidation() && finishTransaction) {
              finishTransaction({ orderID: generateRandomTransactionID() });
            } else {
              onError();
            }
          }}
        >
          Add to Account
        </Button>
      </>
    );
  }

  return (
    <>
      <PaypalButton
        {...props}
        createOrder={
          amount && !createOrder
            ? (data, actions) => createOrder(data, actions)
            : (data, actions) => createOrder(data, actions)
        }
        onInit={(data, actions) => {
          actions.enable();
        }}
        onClick={(data, actions) => {
          if (handleValidation()) {
            return true;
          }
          return false;
        }}
        onApprove={
          finishTransaction
            ? (data, actions) => onApprove(data, actions)
            : (data, actions) => onApprove(data, actions)
        }
        catchError={
          onError ? (error) => onError(error) : (error) => console.error(error)
        }
        onError={
          onError ? (error) => onError(error) : (error) => console.error(error)
        }
        style={{
          layout: "vertical",
          color: "gold",
          shape: "rect",
          label: "paypal",
        }}
      />
    </>
  );
};

export default PaypalButton;
