export function parseErrors(
  errorResponse,
  callback = () => {} /*, idHandlers = {}*/
) {
  // The structure of error responses from Strapi is a bit messy, e.g.:
  // {
  //   "statusCode": 400,
  //   "error": "Bad Request",
  //   "message": [{
  //     "messages": [{
  //       "id": "Auth.form.error.email.taken",
  //       "message": "Email is already taken."
  //     }]
  //   }],
  //   "data": [{
  //     "messages": [{
  //       "id": "Auth.form.error.email.taken",
  //       "message": "Email is already taken."
  //     }]
  //   }]
  // }
  //
  // So, we need to iterate in two steps, but first,
  // we need to check if everything is there.
  // Oh boy...

  if (
    errorResponse &&
    errorResponse.data &&
    errorResponse.data.message &&
    errorResponse.data.message.length
  ) {
    let errorMessages = [];

    if (typeof errorResponse.data.message === "string") {
      errorMessages.push(errorResponse.data.message);
    } else {
      // Iterate over data first to get all message arrays...
      errorResponse.data.message.forEach((messageContainer) => {
        // Another check if the containers have arrays inside
        if (messageContainer.messages && messageContainer.messages.length) {
          let newErrorMessages = [];
          // Let's extract the juicy messages...
          messageContainer.messages.forEach((message) => {
            // This could prove useful later on...
            // if (
            //   message.id
            //   && typeof idHandlers[message.id] === "function"
            // ) {
            //   idHandlers[message.id]();
            // }
            if (message.message) {
              newErrorMessages.push(message.message);
            }
          });
          errorMessages = [...errorMessages, ...newErrorMessages];
        }
      });
    }

    if (typeof callback === "function") {
      callback(errorMessages);
    }

    return errorMessages;
  }
}
