import React, { useState, useEffect } from "react";
import { Button, TextField, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";

import {
  checkPasswordStrength,
  passwordStrengthMessage,
} from "../../utils/checkPassword";
import { verifyEmail } from "../../utils/verifyEmail";

import ErrorAlert from "../ErrorAlert";

import "./Account.scss";
import allActions from "../../actions";

export default function Account({ ...props }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [username, setUsername] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const validateInput = () => {
    let errors = [];
    setErrorMessages([]);
    setPasswordError(false);
    setUsernameError(false);
    setEmailError(false);

    if (password && passwordStrength < 4) {
      errors.push(
        "Your password is too weak, please enter a long, strong password, ideally containing: upper- and lower case letters, numbers and special characters."
      );
      setPasswordError(true);
    }

    if (!password) {
      errors.push("Please enter a password!");
      setPasswordError(true);
    }

    if (!username) {
      errors.push("Please enter a name!");
      setUsernameError(true);
    }

    if (!email) {
      errors.push("Please enter an e-mail address!");
      setEmailError(true);
    }

    if (email && !verifyEmail(email)) {
      errors.push("Please enter a valid e-mail address!");
      setEmailError(true);
    }

    if (errors.length) {
      setErrorMessages(errors);
      return false;
    }

    return true;
  };

  const handleUpdate = async () => {
    if (!user.jwt) {
      return;
    }

    if (!validateInput()) {
      return;
    }

    dispatch(
      allActions.user.updateUser({
        username,
        email,
        password,
        successCallback: () => {
          setUpdateSuccess(true);
        },
        failCallback: (error) => {
          setUpdateSuccess(false);
          setErrorMessages(error.response);
        },
      })
    );
  };

  useEffect(() => {
    if (username === false && user.profile.name) {
      setUsername(user.profile.name);
    }
    if (email === false && user.profile.email) {
      setEmail(user.profile.email);
    }
  }, [username, email, user.profile]);

  if (username === false || email === false) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <article className="account carticle carticle--padded">
      <section className="account-form">
        {updateSuccess && (
          <Alert severity="success">
            Your profile has successfully been updated!
          </Alert>
        )}
        <ErrorAlert errorAction="profile change" errors={errorMessages} />
        <TextField
          required
          autoFocus
          value={username}
          label="Username"
          color="secondary"
          className="account-input"
          onChange={(event) => {
            setUsernameError(false);
            setUsername(event.target.value.trim());
          }}
          error={usernameError}
        />
        <TextField
          required
          value={email}
          label="E-Mail"
          color="secondary"
          className="account-input"
          onChange={(event) => {
            setEmailError(false);
            setEmail(event.target.value.trim());
          }}
          error={emailError}
        />
        <TextField
          className="account-input"
          label="Password"
          type="password"
          color="secondary"
          value={password}
          onChange={(event) => {
            let password = event.target.value.trim();
            setPasswordError(false);
            setPasswordStrength(checkPasswordStrength(password));
            setPassword(password);
          }}
          error={passwordError}
          required
          autoComplete="password"
          helperText={passwordStrengthMessage(passwordStrength)}
        />
        <section className="account-actions">
          <Button onClick={handleUpdate} color="secondary">
            Update Profile
          </Button>
        </section>
      </section>
    </article>
  );
}
