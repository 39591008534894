import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import allActions from "../../actions";

import ErrorAlert from "../ErrorAlert";

import "./Login.scss";

const Login = ({ ...props }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [anchorElement, setAnchorElement] = useState(null);
  let history = useHistory();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleClickOpen = () => {
    // dispatch(allActions.api.apiResetError());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    dispatch(
      allActions.user.loginUser({
        identifier: username,
        password,
        successCallback: () => {
          setOpen(false);
        },
        failCallback: (errors) => {
          setErrorMessages(errors.response);
        },
      })
    );
  };

  const handleLogout = () => {
    setAnchorElement(null);
    dispatch(allActions.user.logoutUser());
    history.push("/");
  };

  const handleMyAccount = () => {
    setAnchorElement(null);
    history.push("/account");
  };

  if (user.jwt && user.profile.name) {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickMenu}
        >
          {user.profile.name}
        </Button>
        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <>
      <Button color="secondary" onClick={handleClickOpen}>
        Login
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="login-dialog"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            handleLogin();
          }
        }}
      >
        <DialogTitle id="responsive-dialog-title">Login</DialogTitle>
        <DialogContent>
          <ErrorAlert errors={errorMessages} />
          <p>Please enter your credentials to proceed.</p>
          <TextField
            required
            autoFocus
            label="Username"
            color="secondary"
            className="login-input"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value.trim());
            }}
            error={!!errorMessages}
          />
          <TextField
            className="login-input"
            label="Password"
            type="password"
            color="secondary"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value.trim());
            }}
            error={!!errorMessages}
            required
            autoComplete="current-password"
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setOpen(false);
              history.push("/loginhelp");
            }}
          >
            Do you need help?
          </Button>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button onClick={handleLogin} color="secondary" autoFocus>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
