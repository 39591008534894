import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import { isEmpty, sortBy, reverse } from "lodash-es";

import { modifyChildren } from "../../utils/modifyChildren";

export function renderList(
  header,
  metalStreamList,
  children,
  isOwnEvent = false,
  isPastEvent = false,
  sort = "asc"
) {
  if (isEmpty(metalStreamList)) {
    return (
      <>
        {!isEmpty(header) && <h2>{header}</h2>}
        <p>No events available.</p>
        {!isPastEvent && (
          <p>
            <strong>
              Do you want to be listed here?{" "}
              <Link component={RouterLink} to="/contact">
                Contact us!
              </Link>
            </strong>
          </p>
        )}
      </>
    );
  }

  // sort out drafts so they aren't shown on production
  if (process.env.REACT_APP_ENV === "production" && !isOwnEvent) {
    metalStreamList = metalStreamList.filter((event) => {
      if (!event.draft) {
        return true;
      }
      return false;
    });
  }

  let sortedMetalStreams = sortBy(metalStreamList, ["date"]);
  if (sort === "desc") {
    sortedMetalStreams = reverse(sortedMetalStreams);
  }

  return (
    <>
      {!isEmpty(header) && <h2>{header}</h2>}
      {sortedMetalStreams.map((metalStream) =>
        modifyChildren(children, {
          key: metalStream.id || Math.random().toString(),
          dataSource: metalStream || {},
          isPastEvent: isPastEvent,
          isOwnEvent: isOwnEvent,
        })
      )}
    </>
  );
}
