import React, { Fragment as F } from "react";
import { isArray } from "lodash-es";
import { Alert } from "@material-ui/lab";

import { parseErrors } from "../../utils/parseErrors";

export default function ErrorAlert({
  errorAction = "request",
  errors = {},
  ...props
}) {
  let errorMessages = errors;
  if (!isArray(errorMessages)) {
    errorMessages = parseErrors(errors);
  }

  if (!errorMessages || !errorMessages.length) {
    return <></>;
  }

  return (
    <Alert severity="error">
      {!errorMessages.length && (
        <>
          <p>
            There has been an error while processing your {errorAction}. Please
            try again later.
          </p>
        </>
      )}
      {errorMessages.length === 1 && (
        <>
          <p>There has been an error while processing your {errorAction}:</p>
          <p>
            {errorMessages.map((error, index) => (
              <F key={index}>{error}</F>
            ))}
          </p>
        </>
      )}
      {errorMessages.length > 1 && (
        <>
          There have been errors while processing your {errorAction}:
          <ul>
            {errorMessages.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </>
      )}
    </Alert>
  );
}
