import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Article from "../Article";
import List from "../List";
import FAQ from "../FAQ";
import Contact from "../Contact";
import Imprint from "../Imprint";
import Privacy from "../Privacy";
import TermsOfService from "../TermsOfService";
import Player from "../Player";
import Activation from "../Activation";
import Account from "../Account";
import LoginReset from "../LoginReset";
import Checkout from "../Checkout";
import About from "../About";

const Routing = ({ boughtEvents, events, ...props }) => {
  return (
    <>
      <Switch>
        {!boughtEvents && (
          <Route exact path="/myevents">
            <Redirect to="/" />
          </Route>
        )}
        {boughtEvents && (
          <Route path="/myevents">
            <h1>My Events</h1>
            <List dataSource={boughtEvents.events} display="own">
              <Article />
            </List>
          </Route>
        )}
        <Route path="/events">
          <h1>Upcoming Events</h1>
          <List dataSource={events.upcoming}>
            <Article />
          </List>
        </Route>
        <Route path="/past">
          <h1>Past Events</h1>
          <List dataSource={events.past} display="past">
            <Article />
          </List>
        </Route>
        <Route path="/faq">
          <h1>FAQ</h1>
          <FAQ />
        </Route>
        <Route path="/contact">
          <h1>Contact</h1>
          <Contact />
        </Route>
        <Route path="/imprint">
          <h1>Legal Disclosure</h1>
          <Imprint />
        </Route>
        <Route path="/privacy">
          <h1>Privacy Policy</h1>
          <Privacy />
        </Route>
        <Route path="/termsofservice">
          <h1>Terms of Service</h1>
          <TermsOfService />
        </Route>
        <Route path="/play/:streamId">
          <Player isSolo={true} />
        </Route>
        <Route path="/playmyevent/:streamId">
          <Player source="owned" />
        </Route>
        <Route path="/activate/:jwt">
          <h1>Account Activation</h1>
          <Activation />
        </Route>
        <Route path="/loginhelp">
          <h1>Forgot Password</h1>
          <LoginReset />
        </Route>
        <Route path="/account">
          <h1>My Account</h1>
          <Account />
        </Route>
        <Route path="/checkout">
          <h1>Checkout</h1>
          <Checkout />
        </Route>
        <Route path="/about">
          <h1>About</h1>
          <About />
        </Route>
        <Route>
          <Redirect to="/events" />
        </Route>
      </Switch>
    </>
  );
};

export default Routing;
