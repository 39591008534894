import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import parse from "html-react-parser";

import { makeStyles } from "@material-ui/core/styles";

import { parseDataSource } from "../../utils/parseDataSource";

import "./Cardicle.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "18rem",
  },
  media: {
    width: "18rem",
    paddingTop: "18rem",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function Cardicle({
  title,
  date = false,
  image = false,
  renderMoreInformation = false,
  dataSource,
  children,
  ...props
}) {
  const classes = useStyles();
  let article = {};
  let eventDate = 0;
  if (dataSource) {
    [article, eventDate] = parseDataSource(dataSource);
    title = article.bandName;
    image = process.env.REACT_APP_API_URL + article.image.url;
    date = eventDate;
    children = parse(article.description);
  }
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid item>
        <Card className={classes.root}>
          {date ? (
            <CardHeader title={title} subheader={date} />
          ) : (
            <CardHeader title={title} />
          )}
          <CardMedia className={classes.media} image={image} title={title} />
          {article.eventInfolink && (
            <Button
              orientation="vertical"
              aria-label="event interactions"
              color="secondary"
              className="single-stream-actions"
              href={article.eventInfolink}
              target="_blank"
            >
              VISIT EXTERNAL SITE
            </Button>
          )}
          <CardActions disableSpacing>
            <Button
              className="single-stream-actions"
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show Details"
            >
              <ExpandMoreIcon /> Show Details
            </Button>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {children}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    </>
  );
}
