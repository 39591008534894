// TODO This whole file is rather large and needs to be split up.
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Link,
  TextField,
  LinearProgress,
  CircularProgress,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { find, isNaN } from "lodash-es";
import NumberFormat from "react-number-format";

import allActions from "../../actions";

// import Parallax from "../Parallax";
import ErrorAlert from "../ErrorAlert";
import PaypalButton from "../PaypalButton";

import { parseDataSource } from "../../utils/parseDataSource";
import AllCountries from "../../utils/allCountries";

export default function Payment({ ...props }) {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const now = new Date();

  const [donationValue, setDonationValue] = useState("0");
  const [customDonation, setCustomDonation] = useState(false);
  const [customDonationAmount, setCustomDonationAmount] = useState(2);
  const [customDonationError, setCustomDonationError] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [birthday, setBirthday] = useState(now);
  const [birthdayError, setBirthdayError] = useState(false);
  const [street, setStreet] = useState("");
  const [streetError, setStreetError] = useState(false);
  const [houseNo, setHouseNo] = useState("");
  const [houseNoError, setHouseNoError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsAcceptedError, setTermsAcceptedError] = useState(false);

  let { eventId } = useParams();
  let eventList = store.events.flat;

  eventId = Number.parseInt(eventId);

  const currentEvent = find(eventList, {
    id: eventId,
  });

  if (!currentEvent) {
    history.push("/");
  }

  const isAlreadyBought = !!~store.boughtEvents.eventIDs.indexOf(eventId);

  /**
   * Let's check if the event is still bookable
   */
  // First, get the event date...
  let lastPossibleBooking = new Date(currentEvent.date);
  // ...and check against today.
  const isEventInTheFuture = lastPossibleBooking - now > 0;

  const ticketLimit = Number.parseInt(currentEvent.ticketLimit);
  let ticketsSoldOut = false;
  if (
    !isNaN(ticketLimit) &&
    ticketLimit !== -1 &&
    ticketLimit - currentEvent.soldTickets <= 0
  ) {
    ticketsSoldOut = true;
  }
  const isTicketStoreOpen = currentEvent.store;
  const isEventBookable = isTicketStoreOpen && isEventInTheFuture;
  const [event, date, time] = parseDataSource(currentEvent);
  const [totalPrice, setTotalPrice] = useState(event.price + donationAmount);

  const showOverlay = (visible = false) => {
    const overlay = document.querySelector(".payment-overlay");

    if (visible) {
      overlay.classList.add("transaction");
    } else {
      overlay.classList.remove("transaction");
    }
  };

  const validateInput = () => {
    let errors = [];
    let birthdayDate = new Date(birthday);

    showOverlay(true);

    setErrorMessages([]);
    setFirstNameError(false);
    setLastNameError(false);
    setStreetError(false);
    setHouseNoError(false);
    setBirthdayError(false);
    setPostalCodeError(false);
    setCityError(false);
    setCountryError(false);
    setTermsAcceptedError(false);
    setCustomDonationError(false);

    if (!firstName) {
      errors.push("Please enter your first name!");
      setFirstNameError(true);
    }

    if (!lastName) {
      errors.push("Please enter your last name!");
      setLastNameError(true);
    }

    if (!street) {
      errors.push("Please enter the name of the street you're living in!");
      setStreetError(true);
    }

    if (!houseNo) {
      errors.push(
        "Please enter the street number of the house you're living in."
      );
      setHouseNoError(true);
    }

    if (!postalCode) {
      errors.push("Please enter your postal code.");
      setPostalCodeError(true);
    }

    if (!city) {
      errors.push("Please enter the name of the city you're living in.");
      setCityError(true);
    }

    if (!country) {
      errors.push("Please select your country.");
      setCountryError(true);
    }

    if (!termsAccepted) {
      errors.push(
        "Please read and accept the Terms of Serice and the Privacy Policy!"
      );
      setTermsAcceptedError(true);
    }

    if (!birthday) {
      errors.push("Please select your birthday.");
      setBirthdayError(true);
    }

    if (birthday.toString() === "Invalid Date") {
      errors.push(
        "Please enter your birthday correctly (DD.MM.YYYY) or simply use the date picker."
      );
      setBirthdayError(true);
    }

    if (now.getFullYear() - birthdayDate.getFullYear() < 18) {
      errors.push("You must be at least be 18 years or older to buy a ticket.");
      setBirthdayError(true);
    }

    showOverlay(false);

    if (errors.length) {
      setErrorMessages(errors);
      return false;
    }

    return true;
  };

  const finishTransaction = (paypalResponse) => {
    showOverlay(true);

    dispatch(
      allActions.user.checkout({
        event: eventId,
        firstName,
        lastName,
        street,
        houseNo,
        postalCode,
        city,
        country,
        birthday,
        termsAccepted,
        orderID: paypalResponse.orderID,
        // TODO Make sure that the amount further below is adjusted too when this is implemented
        donation: donationAmount,
        successCallback: () => {
          showOverlay(false);
          //history.push(`/checkout/summary/${eventId}`);
        },
        failCallback: (error) => {
          console.error(error.response);
          showOverlay(false);
          setErrorMessages(error.response);
        },
      })
    );
  };

  useEffect(() => {
    let total = Number.parseFloat(event.price + donationAmount);
    if (totalPrice !== total) {
      setTotalPrice(total);
    }
  }, [event.price, donationAmount, totalPrice]);

  useEffect(() => {
    if (
      customDonation &&
      !isNaN(customDonationAmount) &&
      customDonationAmount >= 0 &&
      donationAmount !== customDonationAmount
    ) {
      setDonationAmount(customDonationAmount);
    }
  }, [customDonation, customDonationAmount, donationAmount]);

  if (!eventId) {
    return <></>;
  }

  if (eventList === false) {
    return <LinearProgress color="secondary" />;
  }

  if (!isEventBookable || ticketsSoldOut) {
    return (
      <>
        <h2>Sorry, tickets for this event are currently not available.</h2>
        <p>
          Please check{" "}
          <Link component={RouterLink} to="/events" color="secondary">
            our other events
          </Link>
          .
        </p>
      </>
    );
  }

  if (isAlreadyBought) {
    history.push(`/checkout/summary/${eventId}`);
  }

  return (
    <>
      <article className="payment">
        <div className="payment-overlay">
          <div className="payment-overlay-content">
            <CircularProgress size={80} color="secondary" />
            <h4>Please wait...</h4>
          </div>
        </div>
        <header className="payment-header">
          <h2>{event.bandName}</h2>
          <h3>
            {date} at {time}
          </h3>
          <img
            className="payment-headerimage lazyload"
            src={process.env.REACT_APP_API_URL + event.image.url}
            alt={event.bandName}
          />
          {/* {event.image && event.image.url && (
            <Parallax backgroundImage={process.env.REACT_APP_API_URL + event.image.url} height="10rem"></Parallax>
          )} */}
          <ErrorAlert errorAction="order" errors={errorMessages} />
        </header>
        <section className="payment-personaldata carticle carticle--padded carticle--lighten">
          <header className="payment-description">
            <h3>Your Credentials</h3>
            <p>
              Please give us your name and address, these are needed to fulfill
              your order.
            </p>
          </header>
          <TextField
            className="payment-firstname"
            label="First Name"
            color="secondary"
            value={firstName}
            onChange={(event) => {
              setFirstNameError(false);
              setFirstName(event.target.value);
            }}
            error={firstNameError}
          />
          <TextField
            className="payment-firstname"
            label="Last Name"
            color="secondary"
            value={lastName}
            onChange={(event) => {
              setLastNameError(false);
              setLastName(event.target.value);
            }}
            error={lastNameError}
          />
          <TextField
            label="Street"
            className="payment-street"
            color="secondary"
            value={street}
            onChange={(event) => {
              setStreetError(false);
              setStreet(event.target.value);
            }}
            error={streetError}
          />
          <TextField
            label="No."
            className="payment-streetno"
            color="secondary"
            value={houseNo}
            onChange={(event) => {
              setHouseNoError(false);
              setHouseNo(event.target.value);
            }}
            error={houseNoError}
          />
          <TextField
            label="Postal Code"
            className="payment-postalcode"
            color="secondary"
            value={postalCode}
            onChange={(event) => {
              setPostalCodeError(false);
              setPostalCode(event.target.value);
            }}
            error={postalCodeError}
          />
          <TextField
            label="City"
            className="payment-city"
            color="secondary"
            value={city}
            onChange={(event) => {
              setCityError(false);
              setCity(event.target.value);
            }}
            error={cityError}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              className="payment-birthday"
              label="Birthday"
              format="dd.MM.yyyy"
              color="secondary"
              value={birthday}
              onChange={(value) => {
                setBirthdayError(false);
                setBirthday(value);
              }}
              KeyboardButtonProps={{
                "aria-label": "your birthday",
              }}
              error={birthdayError}
            />
          </MuiPickersUtilsProvider>
          <AllCountries
            country={country}
            setCountry={setCountry}
            error={countryError}
            setError={setCountryError}
          />
          <aside className="payment-information">
            <h4>Note</h4>
            <p>
              After your checkout, this ticket is applied and bound directly to
              your user account at MetalStreams.tv. After payment, you can find
              it at{" "}
              <Link
                target="_blank"
                component={RouterLink}
                to="/myevents"
                color="secondary"
              >
                My Events
              </Link>
              .
              <br />
              Refunds are possible until two full days before the event is
              happening, please note the{" "}
              <Link
                component={RouterLink}
                target="_blank"
                to="/termsofservice#refund"
                color="secondary"
              >
                refund terms
              </Link>
              .
            </p>
          </aside>
        </section>
        <div className="payment-article">
          <section className="carticle carticle--padded carticle--lighten">
            <h3>Your Cart</h3>
            <table className="payment-table">
              <thead>
                <tr>
                  <td className="payment-table-amount">Amount</td>
                  <td className="payment-table-item">Item</td>
                  <td className="payment-table-price">Price</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="payment-table-amount">1x</td>
                  <td className="payment-table-item">
                    Ticket for {event.bandName} ({date} at {time})
                  </td>
                  <td className="payment-table-price">
                    <NumberFormat
                      value={event.price}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={" €"}
                      displayType={"text"}
                    />
                  </td>
                </tr>
                {donationAmount > 0 && (
                  <tr>
                    <td className="payment-table-amount">1x</td>
                    <td className="payment-table-item">Additional donation</td>
                    <td className="payment-table-price">
                      <NumberFormat
                        value={donationAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix={" €"}
                        displayType={"text"}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan="2"
                    className="payment-table-item payment-table-item-total"
                  >
                    Total (incl. taxes):
                  </td>
                  <td className="payment-table-price">
                    <NumberFormat
                      value={totalPrice}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={" €"}
                      displayType={"text"}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </section>
          <section className="payment-donation carticle carticle--padded carticle--lighten">
            <h3>Feeling generous?</h3>
            <p>
              If you want, you can spare an additional dime and support the
              crew.
            </p>
            <FormControl
              component="fieldset"
              className="payment-regular-donation"
            >
              <RadioGroup
                aria-label="donation"
                name="donation"
                value={donationValue}
                onChange={(event) => {
                  let value = event.target.value;
                  setDonationValue(value);

                  if (value === "custom") {
                    setCustomDonation(true);
                    if (!isNaN(customDonationAmount)) {
                      setDonationAmount(parseFloat(customDonationAmount));
                    }
                  } else {
                    setCustomDonation(false);
                    setDonationAmount(parseFloat(value));
                  }
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="No, I'm fine."
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="I'd like to leave a 1€ tip."
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="I'd like to leave a 5€ tip."
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="I'd like to leave a 10€ tip."
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label={
                    <div className="payment-custom-donation">
                      I'd like to leave a custom tip
                      {!customDonation ? (
                        "."
                      ) : (
                        <>
                          {": "}
                          <TextField
                            color="secondary"
                            value={customDonationAmount}
                            type="number"
                            className="payment-custom-donation-amount"
                            onChange={(event) => {
                              let customDonationAmount = parseFloat(
                                event.target.value
                              );
                              if (
                                !isNaN(customDonationAmount) &&
                                customDonationAmount > 0 &&
                                customDonationAmount <= 999999
                              ) {
                                setCustomDonationAmount(customDonationAmount);
                              } else if (
                                !isNaN(customDonationAmount) &&
                                customDonationAmount > 999999
                              ) {
                                setCustomDonationAmount(999999);
                              } else {
                                setCustomDonationAmount(0);
                              }
                            }}
                            error={customDonationError}
                          />{" "}
                          €
                        </>
                      )}
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </section>
        </div>
        <footer className="payment-footer">
          <div className="payment-footer-terms">
            <FormControl required error={termsAcceptedError}>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={termsAccepted}
                    onChange={(event) => {
                      setTermsAcceptedError(false);
                      setTermsAccepted(event.target.checked);
                    }}
                    name="terms_accepted"
                  />
                }
                label={
                  <FormLabel component="legend">
                    I have read and accepted the{" "}
                    <Link
                      component={RouterLink}
                      target="_blank"
                      to="/termsofservice"
                      color="secondary"
                    >
                      Terms of Service
                    </Link>{" "}
                    and the{" "}
                    <Link
                      component={RouterLink}
                      target="_blank"
                      to="/privacy"
                      color="secondary"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </FormLabel>
                }
              />
            </FormControl>
          </div>
          <PaypalButton
            amount={totalPrice}
            handleValidation={validateInput}
            onError={(err) => {
              showOverlay(false);
              console.error(err);
            }}
            finishTransaction={finishTransaction}
            onCancel={(data) => {
              showOverlay(false);
              console.warn(data);
            }}
          />
        </footer>
      </article>
    </>
  );
}
