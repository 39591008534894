import {
  CHECKOUT,
  CHECKOUT_ERROR,
  SET_EVENTS,
  API,
  FETCH_EVENTS,
  SET_USER,
  REMOVE_USER,
  REGISTER_USER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  UPDATE_ERROR,
  ACTIVATE_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  // RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
  ACTIVATION_SUCCESS,
  ACTIVATION_ERROR,
  FETCH_USER,
  LOGIN_ERROR,
  LOGIN_USER,
  SET_EVENTS_BOUGHT,
  FORGOT_PASSWORD_MAIL,
  FORGOT_PASSWORD_MAIL_ERROR,
  FORGOT_PASSWORD_MAIL_SUCCESS,
} from "./types";
import { apiError } from "./api";

export function fetchEvents({
  failCallback = () => {},
  successCallback = () => {},
}) {
  return apiAction({
    url: "/events",
    onSuccess: setEvents,
    onFailure: apiError,
    failCallback,
    successCallback,
    label: FETCH_EVENTS,
  });
}

function setEvents(data) {
  return {
    type: SET_EVENTS,
    payload: data,
  };
}

export function fetchUser({
  jwt,
  failCallback = () => {},
  successCallback = () => {},
}) {
  return apiAction({
    url: "/checklogin",
    method: "POST",
    accessToken: jwt,
    onSuccess: setUser,
    onFailure: apiError,
    failCallback,
    successCallback,
    label: FETCH_USER,
  });
}

export function activateUser({
  jwt,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: `/auth/email-confirmation?confirmation=${jwt}`,
    method: "GET",
    onSuccess: activationSuccess,
    onFailure: activationError,
    label: ACTIVATE_USER,
    successCallback,
    failCallback,
  });
}

function activationSuccess() {
  return {
    type: ACTIVATION_SUCCESS,
  };
}

function activationError(error) {
  return {
    type: ACTIVATION_ERROR,
    error,
  };
}

export function loginUser({
  identifier,
  password,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/auth/local",
    method: "POST",
    data: {
      identifier,
      password,
    },
    onSuccess: setUser,
    onFailure: loginError,
    label: LOGIN_USER,
    successCallback,
    failCallback,
  });
}

export function registerUser({
  username,
  email,
  password,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/auth/local/register",
    method: "POST",
    data: {
      username,
      email,
      password,
    },
    onSuccess: registerSuccess,
    onFailure: registerError,
    label: REGISTER_USER,
    successCallback,
    failCallback,
  });
}

function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}

function registerError(error) {
  return {
    type: REGISTER_ERROR,
    error,
  };
}

export function forgotPasswordMail({
  email,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/auth/forgot-password",
    method: "POST",
    data: {
      email,
    },
    onSuccess: forgotPasswordMailSuccess,
    onFailure: forgotPasswordMailError,
    label: FORGOT_PASSWORD_MAIL,
    successCallback,
    failCallback,
  });
}

function forgotPasswordMailSuccess() {
  return {
    type: FORGOT_PASSWORD_MAIL_SUCCESS,
  };
}

function forgotPasswordMailError(error) {
  return {
    type: FORGOT_PASSWORD_MAIL_ERROR,
    error,
  };
}

export function resetPassword({
  code,
  password,
  passwordConfirmation,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/auth/reset-password",
    method: "POST",
    data: {
      code,
      password,
      passwordConfirmation,
    },
    onSuccess: setUser,
    onFailure: resetPasswordError,
    label: RESET_PASSWORD,
    successCallback,
    failCallback,
  });
}

// function resetPasswordSuccess() {
//   return {
//     type: RESET_PASSWORD_SUCCESS
//   };
// }

function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error,
  };
}

export function updateUser({
  username,
  email,
  password,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/updateprofile",
    method: "POST",
    data: {
      username,
      email,
      password,
    },
    onSuccess: setUser,
    onFailure: updateError,
    label: UPDATE_USER,
    successCallback,
    failCallback,
  });
}

function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

function updateError(error) {
  return {
    type: UPDATE_ERROR,
    error,
  };
}

export function logoutUser() {
  return {
    type: REMOVE_USER,
  };
}

function setUser(data) {
  return {
    type: SET_USER,
    payload: { ...data },
  };
}

export function fetchBoughtEvents() {
  return apiAction({
    url: "/myevents",
    method: "GET",
    onSuccess: setBoughtEvents,
    onFailure: apiError,
    label: FETCH_USER,
  });
}

function setBoughtEvents(data) {
  return {
    type: SET_EVENTS_BOUGHT,
    payload: data,
  };
}

export function checkout({
  event,
  firstName,
  lastName,
  street,
  houseNo,
  postalCode,
  city,
  country,
  birthday,
  termsAccepted,
  donation,
  orderID,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return apiAction({
    url: "/buyticket",
    method: "POST",
    data: {
      event,
      firstName,
      lastName,
      street,
      houseNo,
      postalCode,
      city,
      country,
      birthday,
      termsAccepted,
      donation,
      orderID,
    },
    onSuccess: fetchBoughtEvents,
    onFailure: checkoutError,
    label: CHECKOUT,
    successCallback,
    failCallback,
  });
}

function checkoutError(error) {
  return {
    type: CHECKOUT_ERROR,
    error,
  };
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null,
  successCallback = () => {},
  failCallback = () => {},
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
      successCallback,
      failCallback,
    },
  };
}
