import { isArray } from "lodash-es";
import {
  SET_EVENTS,
  SET_EVENTS_FLAT,
  SET_EVENTS_PAST,
  SET_EVENTS_UPCOMING,
} from "../actions/types";

const displayTimeForPastEvents =
  86400000 *
  Number.parseInt(process.env.REACT_APP_DISPLAY_PAST_EVENTS_FOR_DAYS);

const eventStructure = {
  flat: false,
  upcoming: false,
  past: false,
};

const events = (state = eventStructure, action) => {
  switch (action.type) {
    case SET_EVENTS:
      return parseEvents(action.payload);
    case SET_EVENTS_FLAT:
      return {
        ...state,
        flat: action.payload,
      };
    case SET_EVENTS_UPCOMING:
      return {
        ...state,
        upcoming: action.payload,
      };
    case SET_EVENTS_PAST:
      return {
        ...state,
        past: action.payload,
      };
    default:
      return state;
  }
};

function parseEvents(data) {
  if (!isArray(data)) {
    return {};
  }

  let metalStreamsBuffer = [],
    metalStreamsPastBuffer = [];
  let now = new Date();

  data.forEach((metalStream) => {
    if (!metalStream.date) {
      return;
    }

    const eventDateTime = new Date(metalStream.date);

    if (now - eventDateTime <= 0) {
      metalStreamsBuffer.push(metalStream);
    } else if (now - eventDateTime < displayTimeForPastEvents) {
      metalStreamsPastBuffer.push(metalStream);
    }
  });

  return {
    flat: data,
    upcoming: metalStreamsBuffer,
    past: metalStreamsPastBuffer,
  };
}

export default events;
