import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import allActions from "../../actions";

import { useMountEffect } from "../../utils/useMountEffect";

import "./Activation.scss";

export default function Article({ ...props }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { jwt } = useParams(null);

  useMountEffect(() => {
    if (!jwt || user.jwt) {
      history.push("/");
      return;
    }

    dispatch(
      allActions.user.activateUser({
        jwt,
        successCallback: () => {
          setTimeout(() => {
            history.push("/");
          }, 5000);
        },
        failCallback: (error) => {
          console.error(error.message);
        },
      })
    );
  });

  return (
    <>
      <section className="activation">
        <CircularProgress className="activation-animation" color="secondary" />
        <p>
          Just a moment, we're activating your account and will redirect you to
          the start page...
        </p>
      </section>
    </>
  );
}
