import React from "react";
import {
  Link,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import Metalhand from "../Metalhand";
import Player from "../Player";

import "./FAQ.scss";

export default function FAQ({ ...props }) {
  return (
    <>
      <section className="faq">
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="what-is-metalstreams-tv-content"
            id="what-is-metalstreams-tv-header"
          >
            <Typography>
              <strong>What is MetalStreams.tv?</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="details">
            <Typography>
              MetalStreams.tv <Metalhand /> is a website that aims to offer
              bands the opportunity to stream their own live shows and support
              them with the setup. You can sign up for an account and buy
              tickets to see your favorite bands&#39; livestreams as soon as
              they happen here. If you miss it, you can watch the video on
              demand version of it later.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="how-much-does-it-cost-content"
            id="how-much-does-it-cost-header"
          >
            <Typography>
              <strong>How much does it cost?</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="details">
            <Typography>
              The service itself is free, bands decide how much a ticket for
              their shows will cost you. <Metalhand />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="testing-content"
            id="testing-header"
          >
            <Typography>
              <strong>Can I test if your streams are working for me?</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="details">
            <p>Yes, just try the stream down below.</p>
            <Player play="6" />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="who-is-behind-metalstreams-tv-content"
            id="who-is-behind-metalstreams-tv-header"
          >
            <Typography>
              <strong>Who is behind MetalStreams.tv?</strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="details">
            <Typography>
              MetalStreams.tv has been created by two metalheads from Germany:{" "}
              <Link
                target="_blank"
                href="https://twitter.com/choseh"
                color="secondary"
              >
                Alex
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                href="https://twitter.com/thetalesofimmo"
                color="secondary"
              >
                Sven
              </Link>
              .<br />
              Normally, we're an IT Systems Engineer and Developer in the
              tourism sector, but as Covid-19 entered the stage, we needed to
              stay home. Frustrated by not being able to work for quite a long
              time, this project has been created in early April 2020. <br />
              You can find more details about us{" "}
              <Link component={RouterLink} to="/about" color="secondary">
                here.
              </Link>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="development-content"
            id="development-header"
          >
            <Typography>
              <strong>
                How far is development? What's ready and what's coming next?
              </strong>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="details">
            <strong>Here's what's ready by now:</strong>
            <ul>
              <li>Streaming Backend</li>
              <li>Content Library</li>
              <li>Frontend Application</li>
              <li>User Registration, Account and Login</li>
              <li>Checkout for buying tickets directly on MetalStreams.tv</li>
            </ul>
            <strong>Currently we're working on:</strong>
            <ul>
              <li>
                Chat <Metalhand />
              </li>
            </ul>
            <strong>Planned Features:</strong>
            <ul>
              <li>Personal event calendar for all registered users</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </section>
    </>
  );
}
