import {
  checkout,
  activateUser,
  loginUser,
  logoutUser,
  registerUser,
  updateUser,
  fetchBoughtEvents,
  fetchEvents,
  fetchUser,
  resetPassword,
  forgotPasswordMail,
} from "./communication";
import { apiResetError } from "./api";

const allActions = {
  events: {
    fetchBoughtEvents,
    fetchEvents,
  },
  user: {
    checkout,
    activateUser,
    loginUser,
    logoutUser,
    fetchUser,
    registerUser,
    updateUser,
    resetPassword,
    forgotPasswordMail,
  },
  api: {
    apiResetError,
  },
};

export default allActions;
