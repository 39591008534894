import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { isEmpty, find } from "lodash-es";
import { useParams } from "react-router-dom";
import VideoPlayer from "./VideoJS";
import parse from "html-react-parser";

import Chat from "../Chat";

import { parseDataSource } from "../../utils/parseDataSource";

import "./Player.scss";

export default function Player({
  isSolo = false,
  source = "all",
  play = false,
  ...props
}) {
  let { streamId } = useParams();
  const store = useSelector((state) => state);
  const articleRef = useRef();
  let metalStreams = store.events.flat;
  let posterUrl = null;
  let withChat = false;

  if (source === "owned") {
    metalStreams = store.boughtEvents.events;
  }

  if (!streamId && play) {
    streamId = play;
  }

  const currentStream = find(metalStreams, {
    id: Number.parseInt(streamId),
  });

  const [article, date, time] = parseDataSource(currentStream);
  withChat = article.chat && article.chat.enabled;

  let baseClasses = isSolo ? "player player--solo" : "player";
  baseClasses = withChat ? baseClasses : `${baseClasses} player--nochat`;

  const toggleHide = () => {
    if (articleRef) {
      articleRef.current.classList.toggle("player--chathidden");
    }
  };

  if (!isEmpty(article.mediastream.livestreamPoster.url)) {
    posterUrl =
      process.env.REACT_APP_API_URL + article.mediastream.livestreamPoster.url;
  }

  if (isEmpty(article.mediastream.videoOnDemand)) {
    return <></>;
  }

  return (
    <>
      <article ref={articleRef} className={baseClasses}>
        {posterUrl && (
          <VideoPlayer
            controls={true}
            sources={article.mediastream.videoOnDemand}
            poster={posterUrl}
            className="video-wrapper"
          />
        )}
        {!posterUrl && (
          <VideoPlayer
            controls={true}
            sources={article.mediastream.videoOnDemand}
            className="video-wrapper"
          />
        )}
        {withChat && (
          <section className="video-chat">
            <Chat
              event={article.mediastream.event}
              toggleHideCallback={toggleHide}
            />
          </section>
        )}
        <section className="video-description">
          <h2>{currentStream.name}</h2>
          <h3>
            Created {date} at {time}
          </h3>
        </section>

        <aside className="video-aside">{parse(article.description)}</aside>
      </article>
    </>
  );
}
