import React, { useEffect } from "react";
import { BrowserRouter, Link as RouterLink } from "react-router-dom";
import { isEmpty } from "lodash-es";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";

import allActions from "../../actions";
import { useMountEffect } from "../../utils/useMountEffect";

import Login from "../Login";
import Registration from "../Registration";
import Routing from "../Routing";
import Navigation from "../Navigation";

import "./Homepage.scss";

const Homepage = ({ ...props }) => {
  const { events, user, boughtEvents } = useSelector((state) => state);
  const dispatch = useDispatch();

  let userData = {};

  useMountEffect(() => {
    const loginCookie = Cookies.get("login");
    try {
      userData = JSON.parse(atob(loginCookie));
      dispatch(
        allActions.user.fetchUser({
          jwt: userData.jwt,
        })
      );
    } catch (error) {
      Cookies.remove("login");
    }
    dispatch(allActions.events.fetchEvents({}));
  });

  useEffect(() => {
    if (user && user.jwt) {
      dispatch(allActions.events.fetchBoughtEvents());
    }
  }, [user, dispatch]);

  if (isEmpty(events)) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <div className="homepage">
        <Navigation />
        <section className="top-bar">
          {!!parseInt(process.env.REACT_APP_REGISTRATION_ENABLED) && (
            <Registration />
          )}
          <Login />
        </section>
        <section className="content-wrapper">
          <Routing boughtEvents={boughtEvents} events={events} />
        </section>
        <footer className="homepage-footer">
          <RouterLink to="/imprint">Imprint</RouterLink>
          <RouterLink to="/contact">Contact Us</RouterLink>
          <RouterLink to="/termsofservice">Terms of Service</RouterLink>
          <RouterLink to="/privacy">Privacy</RouterLink>
          <RouterLink to="/about">About</RouterLink>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default Homepage;
