import React from "react";
import { isEmpty } from "lodash-es";

import { LinearProgress, Grid } from "@material-ui/core";

import { renderList } from "./ListHelpers";

import "./List.scss";

export default function List({
  dataSource = [],
  display = "upcoming",
  withGrid = false,
  ...props
}) {
  let additionalParams = [];

  if (dataSource === false) {
    return <LinearProgress color="secondary" />;
  }

  switch (display) {
    case "past":
      additionalParams = [false, true, "desc"];
      break;
    case "own":
      additionalParams = [true, false, "desc"];
      break;
    case "upcoming":
    default:
      additionalParams = [];
      break;
  }

  if (isEmpty(dataSource)) {
    return (
      <>
        <section className="list">
          <article>This list is currently empty.</article>
        </section>
      </>
    );
  }

  if (withGrid) {
    return (
      <>
        <section className="list">
          <Grid container item spacing={4} xs="auto" justify="center">
            {renderList("", dataSource, props.children, ...additionalParams)}
          </Grid>
        </section>
      </>
    );
  }

  return (
    <>
      <section className="list">
        {renderList("", dataSource, props.children, ...additionalParams)}
      </section>
    </>
  );
}
