import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { find, isEqual, isEmpty } from "lodash-es";
import { Grid } from "@material-ui/core";
import { History, CalendarToday, Folder } from "@material-ui/icons";

import { Tabs, Tab } from "@material-ui/core";

import LinkedLogo from "../LinkedLogo";

import "./Navigation.scss";

const initialNavigation = [
  { title: "Upcoming Events", route: "/events", icon: CalendarToday },
  { title: "Past Events", route: "/past", icon: History },
];

const Navigation = ({ ...props }) => {
  const user = useSelector((state) => state.user);
  const [navigation, setNavigation] = useState(initialNavigation);

  const filterLocation = (route) => {
    if (route === "/") {
      return "/events";
    }
    return route;
  };

  const location = useLocation();
  let currentValue = filterLocation(location.pathname);

  if (user && user.jwt && !find(navigation, { route: "/myevents" })) {
    setNavigation((navigation) => [
      { title: "My Events", route: "/myevents", icon: Folder },
      ...navigation,
    ]);
  } else if (isEmpty(user)) {
    if (!isEqual(navigation, initialNavigation)) {
      setNavigation(initialNavigation);
    }
  }

  if (!find(navigation, { route: currentValue })) {
    currentValue = false;
  }

  return (
    <nav className="navigation navigation-vertical">
      <LinkedLogo
        style={{
          width: "80%",
          margin: "1rem auto",
          textAlign: "center",
        }}
      />
      <Tabs
        variant="fullWidth"
        orientation="horizontal"
        value={currentValue}
        className="navigation-small show-small"
      >
        {navigation.map((element) => {
          const title = element.title;
          const routeLink = element.route;
          return (
            <Tab
              key={title}
              label={title}
              value={routeLink}
              component={RouterLink}
              to={routeLink}
            />
          );
        })}
      </Tabs>
      <Tabs orientation="vertical" value={currentValue} className="hide-small">
        {navigation.map((element) => {
          const title = element.title;
          const label = (
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item className="navigation-icon">
                  <element.icon />
                </Grid>
                <Grid item>{element.title}</Grid>
              </Grid>
            </>
          );
          const routeLink = element.route;
          return (
            <Tab
              key={title}
              label={label}
              value={routeLink}
              component={RouterLink}
              to={routeLink}
              className="tab"
            />
          );
        })}
      </Tabs>
    </nav>
  );
};

export default Navigation;
