import { SET_USER, REMOVE_USER } from "../actions/types";
import Cookies from "js-cookie";

const userStructure = {
  jwt: false,
  profile: {
    name: false,
    email: false,
    bannedInChats: [],
    moderatedChats: [],
  },
};

const user = (state = userStructure, action) => {
  switch (action.type) {
    case SET_USER:
      let newState = filterSetUserPayload(state, action.payload);
      Cookies.set("login", btoa(JSON.stringify(newState)), {
        expires: 2,
        sameSite: "strict",
      });
      return newState;
    case REMOVE_USER:
      Cookies.remove("login");
      return { ...userStructure };
    default:
      return state;
  }
};

// TODO: Refactor and cleanup
const filterSetUserPayload = (state, data) => {
  let profile = {
    name: state.profile.name,
    email: state.profile.email,
    bannedInChats: state.profile.bannedInChats,
    moderatedChats: state.profile.moderatedChats,
  };

  if (data.profile) {
    profile = data.profile;
  }

  if (data.username && data.email) {
    profile.name = data.username;
    profile.email = data.email;
  }

  if (data.bannedInChats && data.moderatedChats) {
    profile.bannedInChats = data.bannedInChats;
    profile.moderatedChats = data.moderatedChats;
  }

  if (data.user && data.user.username && data.user.email) {
    profile.name = data.user.username;
    profile.email = data.user.email;
  }

  if (data.user && data.user.bannedInChats && data.user.moderatedChats) {
    profile.bannedInChats = data.user.bannedInChats;
    profile.moderatedChats = data.user.moderatedChats;
  }

  return {
    jwt: data.jwt || state.jwt,
    profile,
  };
};

export default user;
