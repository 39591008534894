import axios from "axios";
import { API } from "../actions/types";
import {
  accessDenied,
  apiError,
  apiStart,
  apiEnd,
  apiResetError,
} from "../actions/api";

const apiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type !== API) return;

  const {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onFailure,
    label,
    headers,
    successCallback,
    failCallback,
  } = action.payload;
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  const { user } = getState();
  const bearerToken = user.jwt || accessToken;

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  if (bearerToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  dispatch(apiResetError());

  if (label) {
    dispatch(apiStart(label));
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
    })
    .then(({ data }) => {
      dispatch(onSuccess(data));
      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      if (typeof failCallback === "function") {
        failCallback(error);
      }
      dispatch(apiError(error));
      dispatch(onFailure(error));

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label));
      }
    });
};

export default apiMiddleware;
