import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

const VideoPlayer = (props) => {
  let videoNode = useRef();

  useEffect(() => {
    let currentReference = { ...videoNode };
    videojs(videoNode.current, props);

    // cleanup
    // TODO: This seems not to work...
    return () => {
      if (currentReference && currentReference.dispose) {
        currentReference.dispose();
      }
    };
  });

  return (
    <div className="video-wrapper">
      <div data-vjs-player>
        <video
          ref={videoNode}
          className="video-js video-player vjs-big-play-centered"
        ></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
