import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";

import allActions from "../../actions";
import { useMountEffect } from "../../utils/useMountEffect";

import HomepageApp from "../Homepage";
import MarketingApp from "../Marketing";

const Root = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  let userData = {};

  useMountEffect(() => {
    const loginCookie = Cookies.get("login");
    try {
      userData = JSON.parse(atob(loginCookie));
      dispatch(
        allActions.user.fetchUser({
          jwt: userData.jwt,
        })
      );
    } catch (error) {
      Cookies.remove("login");
    }
    dispatch(
      allActions.events.fetchEvents({
        successCallback: () => {
          setIsLoading(false);
        },
      })
    );
  });

  if (isLoading) {
    return <></>;
  }

  if (user.jwt) {
    return <HomepageApp />;
  }

  return <MarketingApp />;
};

export default Root;
