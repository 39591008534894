import {
  API_START,
  API,
  API_END,
  API_ERROR,
  API_RESET_ERROR,
  ACCESS_DENIED,
} from "../actions/types";

const initialStructure = {
  messages: [],
  errors: [],
  currentError: "",
};

const apiMessages = (state = initialStructure, action) => {
  let returnValue = { ...state };

  switch (action.type) {
    case API:
    case API_START:
    case API_END:
    case ACCESS_DENIED:
      returnValue.messages.push(action.payload);
      return returnValue;
    case API_ERROR:
      returnValue.errors.push(action.error);
      returnValue.currentError = action.error.response;
      return returnValue;
    case API_RESET_ERROR:
      returnValue.currentError = "";
      return returnValue;
    default:
      return state;
  }
};

export default apiMessages;
