import React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { isEmpty, find } from "lodash-es";
import NumberFormat from "react-number-format";
import parse from "html-react-parser";

import { parseDataSource } from "../../utils/parseDataSource";

import "./Article.scss";

export default function Article({
  dataSource,
  isPastEvent = false,
  isOwnEvent = false,
  ...props
}) {
  const [article, date, time] = parseDataSource(dataSource);
  const store = useSelector((state) => state);

  const isAlreadyBought = !!~store.boughtEvents.eventIDs.indexOf(article.id);

  /**
   * Let's check if the event is in the future.
   */
  // First, get the event date...
  let lastPossibleBooking = new Date(article.date);
  // ...and check against today.
  const isEventInTheFuture = lastPossibleBooking - new Date() > 0;
  const articleHasTransaction = !isEmpty(
    find(store.boughtEvents.transactions, {
      event: article.id,
    })
  );
  const ticketLimit = Number.parseInt(dataSource.ticketLimit);
  let ticketsSoldOut = false;
  if (
    !isNaN(ticketLimit) &&
    ticketLimit !== -1 &&
    ticketLimit - dataSource.soldTickets <= 0
  ) {
    ticketsSoldOut = true;
  }
  const renderOrderSummary = isOwnEvent && articleHasTransaction;
  const renderMoreInformation =
    !isPastEvent && !isOwnEvent && article.eventInfolink;
  // const renderLivestreamExternal = !isPastEvent && article.mediastream.livestreamExternal;
  // const renderLivestreamInternal = !isPastEvent && article.mediastream.livestreamInternal;
  const renderVODExternal =
    isPastEvent && article.mediastream.videoOnDemandExternal;
  const renderVODInternal = isOwnEvent && article.mediastream.videoOnDemand;
  const renderBoughtTicket =
    !isOwnEvent && isAlreadyBought && isEventInTheFuture;
  const renderBuyTicket =
    !isPastEvent &&
    !isOwnEvent &&
    !isAlreadyBought &&
    article.ticketStore &&
    !ticketsSoldOut &&
    isEventInTheFuture;
  const renderButtonGroup =
    renderMoreInformation ||
    renderVODExternal ||
    renderVODInternal ||
    renderBuyTicket ||
    renderOrderSummary;

  return (
    <>
      <h2 id={`event-${article.id}`} className="stream-title">
        {date}: {article.bandName}
      </h2>
      <article className="single-stream carticle">
        <aside>
          <img
            src={process.env.REACT_APP_API_URL + article.image.url}
            alt={`Event ${dataSource.name}`}
            className="lazyload"
          />
          {renderButtonGroup && (
            <ButtonGroup
              orientation="vertical"
              aria-label="event interactions"
              variant="outlined"
              color="secondary"
              className="single-stream-actions"
            >
              {renderOrderSummary && (
                <Button
                  component={RouterLink}
                  to={`/checkout/summary/${article.id}`}
                  disabled={!articleHasTransaction}
                >
                  Order Summary
                </Button>
              )}
              {renderMoreInformation && (
                <Button href={article.eventInfolink} target="_blank">
                  More Information
                </Button>
              )}
              {renderBoughtTicket && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/myevents#event-${article.id}`}
                >
                  In Possession
                </Button>
              )}
              {renderBuyTicket && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/checkout/buy/${article.id}`}
                >
                  Buy Ticket (
                  <NumberFormat
                    value={article.price}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" €"}
                    displayType={"text"}
                  />
                  )
                </Button>
              )}
              {renderVODExternal && (
                <Button
                  href={article.mediastream.videoOnDemandExternal}
                  target="_blank"
                >
                  Watch Show (External)
                </Button>
              )}
              {/* This could be obsolete. 
              {isPastEvent && article.mediastream.videoOnDemand && (
                <Button
                  component={RouterLink}
                  to={`/play/${article.id}`}
                >
                  Watch Show
                </Button>
              )} */}
              {renderVODInternal && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/playmyevent/${article.id}`}
                >
                  Watch Show
                </Button>
              )}
            </ButtonGroup>
          )}
        </aside>
        <section className="article-details">
          <h3>Details</h3>
          <div>{parse(article.description)}</div>

          <h3>Time and Date</h3>
          <p>
            {date} at {time}
          </p>
          {!isPastEvent && !isOwnEvent && (
            <>
              <h3>Price</h3>
              <p>
                {article.price > 0 && (
                  <NumberFormat
                    value={article.price}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" €"}
                    displayType={"text"}
                  />
                )}{" "}
                {article.priceInformation}
              </p>
            </>
          )}
        </section>
      </article>
    </>
  );
}
