import React from "react";

import "./Parallax.scss";

const Parallax = ({ backgroundImage, height, children, style, ...props }) => {
  return (
    <>
      <section
        className="parallax"
        style={{
          ...style,
          minHeight: height || "40rem",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div>{children}</div>
      </section>
    </>
  );
};

export default Parallax;
