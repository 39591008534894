import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import apiMiddleware from "../middleware/api";

let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(apiMiddleware))
);

export default store;
