import React, { useState } from "react";
import axios from "axios";
import {
  Select,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Grid,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";

import "./Contact.scss";

export default function Contact({ ...props }) {
  const [messageSent, setMessageSent] = useState();
  const [topic, setTopic] = useState("Get listed");
  const [message, setMessage] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [contactMailValidation, setContactMailValidation] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const validateMail = () => {
    const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mailValidation = mailReg.test(String(contactMail).toLowerCase());
    setContactMailValidation(mailValidation);
    return mailValidation;
  };

  const validate = (callback) => {
    const mailValidationStatus = validateMail();
    const validationStatus =
      topic === "" || message === "" || !mailValidationStatus;
    setValidationError(validationStatus);
    if (typeof callback === "function" && !validationStatus) {
      callback();
    }
  };

  const sendMessage = async () => {
    let mailData = {
      sender: contactMail,
      message: message,
      subject: topic,
    };

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/sendmail",
        mailData
      );
      setMessageSent(true);
    } catch (e) {
      console.error("Error while sending the mail: ", e.message);
      setMessageSent(false);
    }
  };

  if (messageSent) {
    return (
      <p>
        <strong>
          Thanks for your message, we'll get back in touch with you soon!
        </strong>
      </p>
    );
  }

  return (
    <>
      <article className="contact carticle carticle--padded">
        <p>
          Your amazing event is not already listed here, you want to stream your
          own event or just want to get in touch with us for something
          completely different?
          <br />
          <br />
          Just use the form below, we are looking forward to hearing from you!
        </p>
        <Grid container className="contact-form">
          {messageSent === false && (
            <Alert severity="error">
              Sorry! Sadly, there has been an error on our side, which means we
              could not send your message. Please try again later or contact us
              via the mail adress provided in our{" "}
              <Link target="_blank" component={RouterLink} to="/imprint">
                imprint
              </Link>
              .
            </Alert>
          )}
          <FormControl>
            <InputLabel
              color="secondary"
              id="contact-topic"
              error={validationError && topic === ""}
            >
              Subject
            </InputLabel>
            <Select
              labelId="contact-topic"
              id="contact-topic-select"
              value={topic}
              onChange={(event) => {
                setTopic(event.target.value);
              }}
              color="secondary"
              error={validationError && topic === ""}
            >
              <MenuItem value="Get listed">Get listed</MenuItem>
              <MenuItem value="Stream your event">Stream your event</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            <TextField
              label="Contact mail"
              className="contact-input"
              multiline
              value={contactMail}
              color="secondary"
              onChange={(event) => {
                setContactMail(event.target.value);
              }}
              error={validationError && !contactMailValidation}
            />
            <TextField
              label="Your message"
              className="contact-input"
              multiline
              value={message}
              color="secondary"
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              error={validationError && message === ""}
            />
            {validationError && (
              <FormHelperText>
                Please fill out the form completely!
              </FormHelperText>
            )}

            <Button
              style={{ marginTop: "2rem" }}
              variant="outlined"
              onClick={() => {
                validate(sendMessage);
              }}
            >
              Send
            </Button>
          </FormControl>
        </Grid>
      </article>
    </>
  );
}
