import { combineReducers } from "redux";

import eventsReducer from "./events";
import boughtEventsReducer from "./boughtEvents";
import userReducer from "./user";
import apiReducer from "./api";

const rootReducer = combineReducers({
  events: eventsReducer,
  boughtEvents: boughtEventsReducer,
  user: userReducer,
  api: apiReducer,
});

export default rootReducer;
