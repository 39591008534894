import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ResetViaMail from "./ResetViaMail";
import ResetPassword from "./ResetPassword";

import "./LoginReset.scss";

export default function LoginReset({ ...props }) {
  let { path } = useRouteMatch();

  return (
    <>
      <article className="loginreset">
        <Switch>
          <Route exact path={path}>
            <ResetViaMail />
          </Route>
          <Route path={`${path}/:resetCode`}>
            <ResetPassword />
          </Route>
        </Switch>
      </article>
    </>
  );
}
