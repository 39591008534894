import React from "react";
import { useSelector } from "react-redux";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Button, Link, LinearProgress } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { find, isEmpty } from "lodash-es";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

import Metalhand from "../Metalhand";

import { parseDataSource } from "../../utils/parseDataSource";

export default function Payment({ ...props }) {
  const store = useSelector((state) => state);
  const history = useHistory();

  let { eventId } = useParams();
  let eventList = store.events.flat;
  let transactionList = store.boughtEvents.transactions;

  eventId = Number.parseInt(eventId);

  const currentEvent = find(eventList, {
    id: eventId,
  });

  const currentTransaction = find(transactionList, {
    event: eventId,
  });

  const isAlreadyBought = !!~store.boughtEvents.eventIDs.indexOf(eventId);
  const [event, date, time] = parseDataSource(currentEvent);

  if (isEmpty(currentTransaction)) {
    history.push("/");
  }

  if (!eventId || !currentTransaction) {
    return <></>;
  }

  if (eventList === false || transactionList === false) {
    return <LinearProgress color="secondary" />;
  }

  // This is weird...
  if (!currentEvent || !currentTransaction) {
    history.push("/");
  }

  if (!isAlreadyBought) {
    history.push(`/checkout/buy/${eventId}`);
  }

  return (
    <>
      <article className="summary">
        <header className="summary-header">
          <Button
            startIcon={<ArrowBackIos />}
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/myevents"
          >
            Go to "My Events"
          </Button>
          <h2>{event.bandName}</h2>
          <h3>
            {date} at {time}
          </h3>
          <img
            className="summary-headerimage lazyload"
            src={process.env.REACT_APP_API_URL + event.image.url}
            alt={event.bandName}
          />
          {/* {event.image && event.image.url && (
            <Parallax backgroundImage={process.env.REACT_APP_API_URL + event.image.url} height="10rem"></Parallax>
          )} */}
        </header>
        <div className="summary-article">
          <h3>Your Purchase</h3>
          <table className="summary-table">
            <thead>
              <tr>
                <td className="summary-table-amount">Amount</td>
                <td className="summary-table-item">Item</td>
                <td className="summary-table-price">Price</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="summary-table-amount">1x</td>
                <td className="summary-table-item">
                  Ticket for {event.bandName} ({date} at {time})
                </td>
                <td className="summary-table-price">
                  <NumberFormat
                    value={event.ticketPrice}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" €"}
                    displayType={"text"}
                  />
                </td>
              </tr>
              {currentTransaction.donation > 0 && (
                <tr>
                  <td className="summary-table-amount">1x</td>
                  <td className="summary-table-item">Additional donation</td>
                  <td className="summary-table-price">
                    <NumberFormat
                      value={currentTransaction.donation}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={" €"}
                      displayType={"text"}
                    />
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan="2"
                  className="summary-table-item summary-table-item-total"
                >
                  Total (incl. taxes):
                </td>
                <td className="summary-table-price">
                  <NumberFormat
                    value={currentTransaction.price}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={" €"}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
          <section className="summary-article-details">
            <h4>Note</h4>
            <p>
              This Ticket has been applied and bound directly to your user
              account at MetalStreams.tv. You can find it at{" "}
              <Link
                target="_blank"
                component={RouterLink}
                to="/myevents"
                color="secondary"
              >
                My Events
              </Link>
              .
              <br />
              Refunds are possible until three days before the event is
              happening, please note the{" "}
              <Link
                component={RouterLink}
                target="_blank"
                to="/termsofservice#ticketsales"
                color="secondary"
              >
                refund terms
              </Link>
              .
            </p>
          </section>
        </div>
        <section className="summary-personaldata">
          <h3>Transaction Details</h3>
          <p>
            Order Date: {new Date(currentTransaction.datetime).toLocaleString()}
            <br />
            {currentTransaction.price + currentTransaction.donation > 0 && (
              <>
                Payment Method: {currentTransaction.paymentMethod}
                <br />
                Transaction ID: {currentTransaction.paypalTransaction}
                <br />
                Payment Status: {currentTransaction.paid ? "Paid" : "Pending"}
                <br />
              </>
            )}
          </p>
          <h4>Your Credentials</h4>
          <p>
            {parse(
              currentTransaction.buyerInformation
                .toString()
                .split("\n")
                .join("<br />")
            )}
          </p>
        </section>
        <footer className="summary-footer">
          Thank you for your purchase and enjoy the show! <Metalhand />
        </footer>
      </article>
    </>
  );
}
