import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Payment from "./Payment";
import Summary from "./Summary";

import "./Checkout.scss";

export default function Checkout({ ...props }) {
  let { path } = useRouteMatch();

  return (
    <>
      <article className="checkout carticle carticle--padded">
        <Switch>
          <Route path={`${path}/buy/:eventId`}>
            <Payment />
          </Route>
          <Route path={`${path}/summary/:eventId`}>
            <Summary />
          </Route>
        </Switch>
      </article>
    </>
  );
}
