import React from "react";

import { Link, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Cardicle from "../Cardicle";
import imageAlex from "../../assets/team/alex.jpg";
import imageJudith from "../../assets/team/judith.jpg";
import imageHeike from "../../assets/team/heike.jpg";
import imageSven from "../../assets/team/sven.jpg";

import imageKeyweb from "../../assets/partners/keyweb.svg";

import "./About.scss";

export default function About({ ...props }) {
  return (
    <>
      <div className="about">
        <section className="about-coverletter carticle carticle--padded">
          <p>
            We are a group of metalheads and realized that the current options
            for streamed live concerts have quite some room for improvement.
          </p>
          <p>
            Most of the current events are streamed for free on big platforms
            with attached donation campaigns, trying to compensate ticket sales.
            It is quite hard to keep finances in check that way. Because of
            this, we created a web application offering a way of selling virtual
            tickets for streamed events in a more predictable way.
          </p>
          <p>
            We aim to provide a solution that strives to be more solid for
            bands, locations and film crews, with donations still being
            possible, but not as the sole way of funding an event. Our solution
            offers fixed ticket prices with optional voluntary tips.
          </p>
          <p>
            We are providing the streaming infrastructure ourselves, leveraging
            our experience from over ten years of hosting infrastructure
            solutions, eliminating the need for additional third party providers
            (except PayPal for payment processing).
          </p>
          <p>
            Additionally, we are cooperating with multiple local film crews in
            Germany to be able to offer their services if needed, in case you do
            not have a local film crew available.
          </p>
          <p>
            We are looking forward to getting something cool going with you in
            the future! For more details,{" "}
            <Link component={RouterLink} to="/contact" color="secondary">
              contact us.
            </Link>
          </p>
        </section>
        <h2 className="about-header">The Team</h2>
        <Grid container item spacing={4} xs="auto" justify="center">
          <Cardicle title="Alex" image={imageAlex}>
            <cite>
              ...I can't talk to you about the next event right now, the servers
              are on fire.
            </cite>
            <h3>Responsibilities</h3>
            <ul>
              <li>PR</li>
              <li>IT-Infrastructure</li>
            </ul>
            <h3>Bands</h3>
            <ul>
              <li>Freedom Call</li>
              <li>Arch Enemy</li>
              <li>The Browning</li>
            </ul>
            <h3>Contact</h3>
            <Link href="mailto:alex@metalstreams.tv">alex@metalstreams.tv</Link>
          </Cardicle>
          <Cardicle title="Sven" image={imageSven}>
            <cite>
              ...<span class="about-pre">git push origin master --force</span> –
              I regret nothing!
            </cite>
            <h3>Responsibilities</h3>
            <ul>
              <li>Development</li>
            </ul>
            <h3>Bands</h3>
            <ul>
              <li>Ghost</li>
              <li>Lord of the Lost</li>
              <li>Heaven Shall Burn</li>
            </ul>
            <h3>Contact</h3>
            <Link href="mailto:sven@metalstreams.tv">sven@metalstreams.tv</Link>
          </Cardicle>
          <Cardicle title="Judith" image={imageJudith}>
            <cite>...no, we're fixing that right now!</cite>
            <h3>Responsibilities</h3>
            <ul>
              <li>Quality Assurance</li>
            </ul>
            <h3>Bands</h3>
            <ul>
              <li>Five Finger Death Punch</li>
              <li>Blacktoothed</li>
              <li>Enter Shikari</li>
            </ul>
            <h3>Contact</h3>
            <Link href="mailto:judith@metalstreams.tv">
              judith@metalstreams.tv
            </Link>
          </Cardicle>
          <Cardicle title="Heike" image={imageHeike}>
            <cite>...and we have to earn some money in the end.</cite>
            <h3>Responsibilities</h3>
            <ul>
              <li>Business Development</li>
            </ul>
            <h3>Bands</h3>
            <ul>
              <li>Equilibrium</li>
              <li>Insomnium</li>
              <li>Calle 13</li>
            </ul>
            <h3>Contact</h3>
            <Link href="mailto:heike@metalstreams.tv">
              heike@metalstreams.tv
            </Link>
          </Cardicle>
        </Grid>
        <h2 className="about-header">Partners</h2>
        <section className="about-partners">
          <Link
            className="about-partner"
            target="_blank"
            href="https://keyweb.de"
          >
            <img src={imageKeyweb} alt="Keyweb AG" />
          </Link>
        </section>
      </div>
    </>
  );
}
