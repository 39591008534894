import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Button } from "@material-ui/core";

import allActions from "../../actions";

import ErrorAlert from "../ErrorAlert";

export default function ResetViaMail({ ...props }) {
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const [errorMessages, setErrorMessages] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const handleVerification = async () => {
    setEmailError(false);
    setErrorMessages(false);

    dispatch(
      allActions.user.forgotPasswordMail({
        email,
        successCallback: () => {
          setEmailSent(true);
        },
        failCallback: (error) => {
          setEmailError(true);
          setErrorMessages(error.response);
        },
      })
    );
  };

  if (emailSent) {
    return (
      <p>
        A verification message has been sent to you, please check your e-mail.
      </p>
    );
  }

  return (
    <>
      <p>
        Did you forget your password? No problem, just reset it. For this to
        work, we'll send a code via e-mail.
      </p>
      <section className="loginreset-form">
        <ErrorAlert errorAction="login reset" errors={errorMessages} />
        <TextField
          className="loginreset-input"
          color="secondary"
          autoFocus
          error={emailError}
          label="E-Mail"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          helperText="Please enter the e-mail address you registered with."
        />
        <section className="loginreset-actions">
          <Button onClick={handleVerification} color="secondary">
            E-Mail Me
          </Button>
        </section>
      </section>
    </>
  );
}
