import React from "react";

import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

export default function Privacy({ ...props }) {
  return (
    <>
      <section className="privacy carticle carticle--padded">
        <article>
          MetalStreams.tv is a project of Alexander Bienzeisler. This privacy
          policy will explain how our project uses the personal data we collect
          from you when you use our website.
        </article>

        <h2>Topics</h2>
        <article>
          <ul>
            <li>
              <Link href="#what-data-do-we-collect" color="secondary">
                What data do we collect?
              </Link>
            </li>
            <li>
              <Link href="#how-do-we-collect-your-data" color="secondary">
                How do we collect your data?
              </Link>
            </li>
            <li>
              <Link href="#how-will-we-use-your-data" color="secondary">
                How will we use your data?
              </Link>
            </li>
            <li>
              <Link href="#how-do-we-store-your-data" color="secondary">
                How do we store your data?
              </Link>
            </li>
            <li>
              <Link href="#marketing" color="secondary">
                Marketing
              </Link>
            </li>
            <li>
              <Link
                href="#what-are-your-data-protection-rights"
                color="secondary"
              >
                What are your data protection rights?
              </Link>
            </li>
            <li>
              <Link href="#what-are-cookies" color="secondary">
                What are cookies?
              </Link>
            </li>
            <li>
              <Link href="#how-do-we-use-cookies" color="secondary">
                How do we use cookies?
              </Link>
            </li>
            <li>
              <Link href="#what-types-of-cookies-do-we-use" color="secondary">
                What types of cookies do we use?
              </Link>
            </li>
            <li>
              <Link href="#how-to-manage-your-cookies" color="secondary">
                How to manage your cookies
              </Link>
            </li>
            <li>
              <Link
                href="#privacy-policies-of-other-websites"
                color="secondary"
              >
                Privacy policies of other websites
              </Link>
            </li>
            <li>
              <Link href="#changes-to-our-privacy-policy" color="secondary">
                Changes to our privacy policy
              </Link>
            </li>
            <li>
              <Link href="#how-to-contact-us" color="secondary">
                How to contact us
              </Link>
            </li>
            <li>
              <Link
                href="#how-to-contact-the-appropriate-authorities"
                color="secondary"
              >
                How to contact the appropriate authorities
              </Link>
            </li>
          </ul>
        </article>

        <h2 id="what-data-do-we-collect">What data do we collect?</h2>

        <article>
          <ul>
            <li>IP addresses</li>
            <li>E-mail addresses (if you registered with us)</li>
            <li>self-assigned Usernames (if you registered with us)</li>
            <li>Your personal address (in case of ticket order)</li>
            <li>Paypal Transaction ID (in case of ticket order)</li>
          </ul>
        </article>

        <h2 id="how-do-we-collect-your-data">How do we collect your data?</h2>
        <article>
          <p>
            You directly provide MetalStreams.tv with most of the data we
            collect. We collect data and process data when you:
          </p>

          <ul>
            <li>
              Register online or place an order for any of our products or
              services.
            </li>
            <li>Use or view our website via your browser’s cookies.</li>
            <li>
              MetalStreams.tv may also receive your data indirectly from the
              following sources:
            </li>
          </ul>

          <p>
            When using our website, your IP address and the requests you're
            making to our server are saved in log files, these are used to
            ensure the function of our webservice and to enable us to solve
            technical problems. Also, these files are used in case of abuse.
          </p>

          <p>
            Apart from that, we're collecting your self-assigned user name and
            email adress when you register with our service.
          </p>
        </article>

        <h2 id="how-will-we-use-your-data">How will we use your data?</h2>
        <article>
          <p>MetalStreams.tv collects your data so that we can:</p>

          <ul>
            <li>Process your order and manage your account.</li>
            <li>
              When MetalStreams.tv processes your order, it may send your data
              to, and also use the resulting information from, credit reference
              agencies to prevent fraudulent purchases.
            </li>
          </ul>
        </article>

        <h2 id="how-do-we-store-your-data">How do we store your data?</h2>
        <article>
          <p>
            MetalStreams.tv securely stores your data at our own servers located
            in Germany.
          </p>

          <p>
            MetalStreams.tv will keep your IP address for one year. Once this
            time period has expired, we will delete your data by erasing the
            containing log files from our servers.
          </p>
        </article>

        <h2 id="marketing">Marketing</h2>
        <article>
          <p>
            MetalStreams.tv might like to send you information about products
            and services of ours that we think you might like in the future.
            Currently there are no plans to share your information with
            partners.
          </p>

          <p>
            If you have agreed to receive marketing, you may always opt out at a
            later date.
          </p>

          <p>
            You have the right at any time to stop MetalStreams.tv from
            contacting you for marketing purposes.
          </p>
          <p>
            If you no longer wish to be contacted for marketing purposes, please{" "}
            <Link component={RouterLink} to="/contact" color="secondary">
              contact us here
            </Link>
            .
          </p>
        </article>
        <h2 id="what-are-your-data-protection-rights">
          What are your data protection rights?
        </h2>
        <article>
          <p>
            MetalStreams.tv would like to make sure you are fully aware of all
            of your data protection rights. Every user is entitled to the
            following:
          </p>

          <ul>
            <li>
              The right to access – You have the right to request
              MetalStreams.tv for copies of your personal data. We may charge
              you a small fee for this service.
            </li>
            <li>
              The right to rectification – You have the right to request that
              MetalStreams.tv correct any information you believe is inaccurate.
              You also have the right to request MetalStreams.tv to complete the
              information you believe is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that
              MetalStreams.tv erase your personal data, under certain
              conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that MetalStreams.tv restrict the processing of your personal
              data, under certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to MetalStreams.tv’s processing of your personal data, under
              certain conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              MetalStreams.tv transfer the data that we have collected to
              another organization, or directly to you, under certain
              conditions.
            </li>
          </ul>

          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please{" "}
            <Link component={RouterLink} to="/contact" color="secondary">
              contact us here
            </Link>{" "}
            or use the data provided in{" "}
            <Link component={RouterLink} to="/imprint" color="secondary">
              our imprint
            </Link>
            .
          </p>
        </article>

        <h2 id="what-are-cookies">Cookies</h2>
        <article>
          <p>
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you
            visit our websites, we may collect information from you
            automatically through cookies or similar technology.
          </p>

          <p>
            For further information, visit{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://allaboutcookies.org"
              color="secondary"
            >
              allaboutcookies.org
            </Link>
            .
          </p>
        </article>

        <h2 id="how-do-we-use-cookies">How do we use cookies?</h2>
        <article>
          <p>
            MetalStreams.tv uses cookies in a range of ways to improve your
            experience on our website, including:
          </p>
          <ul>
            <li>Keeping you signed in</li>
            <li>Understanding how you use our website</li>
          </ul>
        </article>

        <h2 id="what-types-of-cookies-do-we-use">
          What types of cookies do we use?
        </h2>
        <article>
          <p>
            There are a number of different types of cookies, however, our
            website uses:
          </p>

          <ul>
            <li>
              Functionality – MetalStreams.tv uses these cookies so that we
              recognize you on our website and remember your previously selected
              preferences. These could include what language you prefer and
              location you are in. Only first party cookies are used.
            </li>
          </ul>
        </article>
        <h2 id="how-to-manage-your-cookies">How to manage cookies</h2>
        <article>
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, some of
          our website features, especially using your user account, may not
          function as a result.
        </article>

        <h2 id="privacy-policies-of-other-websites">
          Privacy policies of other websites
        </h2>
        <article>
          The MetalStreams.tv website contains links to other websites. Our
          privacy policy applies only to our website, so if you click on a link
          to another website, you should read their privacy policy.
        </article>

        <h2 id="changes-to-our-privacy-policy">
          Changes to our privacy policy
        </h2>
        <article>
          MetalStreams.tv keeps its privacy policy under regular review and
          places any updates on this web page. This privacy policy was last
          updated on 29th of April 2020.
        </article>

        <h2 id="how-to-contact-us">How to contact us</h2>
        <article>
          <p>
            If you have any questions about MetalStreams.tv’s privacy policy,
            the data we hold on you, or you would like to exercise one of your
            data protection rights, please do not hesitate to{" "}
            <Link component={RouterLink} to="/contact" color="secondary">
              contact us here
            </Link>
            .
          </p>

          <p>
            Or write to us at the address provided in{" "}
            <Link component={RouterLink} to="/imprint" color="secondary">
              our imprint
            </Link>
            .
          </p>
        </article>

        <h2 id="how-to-contact-the-appropriate-authorities">
          How to contact the appropriate authority
        </h2>
        <article>
          <p>
            Should you wish to report a complaint or if you feel that
            MetalStreams.tv has not addressed your concern in a satisfactory
            manner, you may contact the privacy authority of the German federal
            country Saxony.
          </p>

          <p>
            Email:{" "}
            <Link href="mailto:saechsdsb@slt.sachsen.de" color="secondary">
              saechsdsb@slt.sachsen.de
            </Link>
          </p>

          <h3>Postal Address</h3>
          <p>
            Sächsischer Datenschutzbeauftragter
            <br />
            Postfach 11 01 32
            <br />
            01330 Dresden
            <br />
          </p>

          <h3>Visiting Address</h3>
          <p>
            Sächsischer Datenschutzbeauftragter
            <br />
            Devrientstraße 5<br />
            01067 Dresden
            <br />
          </p>
        </article>

        <h2>Additional information</h2>
        <article>
          This privacy notice has been based on the template provided by{" "}
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href="https://gdpr.eu/privacy-notice/"
            color="secondary"
          >
            GDPR.eu
          </Link>
          .
        </article>
      </section>
    </>
  );
}
